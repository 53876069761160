import './Footer.css';
import whiteIcon from '../../assets/W.png';
import { useContext } from 'react';
import { ActiveTheme } from '../../App';

export default function Footer() {
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    return (
        <footer style={{backgroundColor: mainColor()}}>
            <img src={whiteIcon} alt=""/>
            <p>جميع الحقوق محفوظة <br /> 2024&copy;</p>
            <div className="socials">
                <a href="https://www.facebook.com/paperbook.ly/" style={{color: 'white'}} target="_blank"><i class="fab fa-facebook"></i></a>
                <a href="https://instagram.com/paperbook.ly?utm_medium=copy_link" style={{color: 'white'}} target="_blank"><i class="fab fa-instagram"></i></a>
                <a href="https://wa.me/message/CYEWTWURGK3IA1" style={{color: 'white'}} target="_blank"><i class="fab fa-whatsapp"></i></a>
                <a href="https://t.me/paperbookly" style={{color: 'white'}} target="_blank"><i class="fab fa-telegram-plane"></i></a>
            </div>
        </footer>
    )
}