import { useLocation, useHistory } from 'react-router-dom';
import './Record.css';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function Record(props) {
    const location = useLocation();
    const {activeTheme, setActiveTheme} = useContext(ActiveTheme);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const [deleteContainerVisiability, setDeleteContainerVisiability] = useState(false);
    const apiEndPoint = props.apiEndPoint;
    const { recordInfo } = location.state;
    console.log(recordInfo);
    const cart = JSON.parse(recordInfo.userCart);
    const history = useHistory();

    let originalCartPrice = 0;
    let totalSale = 0;
    let originalProfit = 0;
    let titles = Object.keys(cart);
    for (let i=0;i<titles.length;i++) {
        originalCartPrice += cart[titles[i]].quantity * cart[titles[i]].singleBookPrice;
        totalSale += cart[titles[i]].quantity * cart[titles[i]].singleSellSale;
        originalProfit += cart[titles[i]].quantity * cart[titles[i]].singleSellProfit;
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
        document.title = `Paperbook - Record#${recordInfo.id}`;
    }, []);

    const showItems = (cartObj) => {
        console.log(cartObj)
        const arr = Object.keys(cartObj);
        return (
        <td>
            {arr.map((key) => (
            <p>
                <b>{key}: </b> 
                <p>الكمية: {cartObj[key].quantity}</p>
                <p>السعر الاصلي: {cartObj[key].singleBookPrice * cartObj[key].quantity}</p>
                <p>التخفيض الكلي: {cartObj[key].singleSellSale * cartObj[key].quantity}</p>
                <p>المدفوع: {cartObj[key].quantity * (cartObj[key].singleBookPrice - cartObj[key].singleSellSale)}</p>
                <p>المربح: {cartObj[key].quantity * (cartObj[key].singleSellProfit - cartObj[key].singleSellSale)}</p>
            </p>
            ))}
        </td>
        );
    };

    const showConfirmDeleteContainer = () => {
        setDeleteContainerVisiability(true);
    }

    const deleteBtn = () => {
        setIsBtnDisabled(true);
        axios.get(`${apiEndPoint}/api/deleteSellsRecord/${recordInfo.id}`).then(response => {
            if (response.data) {
                setIsBtnDisabled(false);
                history.replace('/admin/sellsRecords');
            }
        });
    }


    return (
        <div className="record">
            <h1 style={{direction: 'rtl', textAlign: 'center'}}>طلبية {recordInfo.id}#</h1>
            <table className="detailedInfo">
                <tr>
                    <td>
                        <p>{recordInfo.username}</p>
                    </td>
                    <td>
                        <b>:الاسم</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{recordInfo.userPhoneNumber}</p>
                    </td>
                    <td>
                        <b>:رقم الهاتف</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{(recordInfo.driverName) ? recordInfo.driverName : 'شاغر'}</p>
                    </td>
                    <td>
                        <b>:السائق</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{recordInfo.deliveryTime}</p>
                    </td>
                    <td>
                        <b>:التاريخ</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{recordInfo.totalPrice}</p>
                    </td>
                    <td>
                        <b>:السعر</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{originalCartPrice}</p>
                    </td>
                    <td>
                        <b>:السعر الاصلي (بدون تخفيض)</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{totalSale}</p>
                    </td>
                    <td>
                        <b>:قيمة التخفيض</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{recordInfo.walletBalanceUsed}</p>
                    </td>
                    <td>
                        <b>:المدفوع من المحفظة</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{originalProfit}</p>
                    </td>
                    <td>
                        <b>:الارباح (قبل التخفيض)</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{recordInfo.totalProfit}</p>
                    </td>
                    <td>
                        <b>:اجمالي الارباح</b>
                    </td>
                </tr>
                <tr>
                    {showItems(cart)}
                    <td>
                        <b>:المشتريات</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{recordInfo.deliveryLocation}</p>
                    </td>
                    <td>
                        <b>:العنوان</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{recordInfo.manual ? 'يدوية' : 'تلقائية'}</p>
                    </td>
                    <td>
                        <b>:بيعت بطريقة</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>{(recordInfo.userNotes.length > 0) ? recordInfo.userNotes : 'لايوجد ملاحظات'}</p>
                    </td>
                    <td>
                        <b>:ملاحظات المشتري</b>
                    </td>
                </tr>
            </table>
            <button  className="flatBtn" style={{ display: deleteContainerVisiability ? 'none' : null, backgroundColor: mainColor()}} onClick={showConfirmDeleteContainer}>حذف</button>
            <div className="record-confirmDeleteBtns" style={{ display: deleteContainerVisiability ? 'block' : 'none' }}>
                <b>هل تريد الحذف؟</b>
                <button disabled={isBtnDisabled} className="flatBtn" style={{ backgroundColor: mainColor() }} onClick={deleteBtn}>
                    {isBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'حذف'}
                </button>
                <button disabled={isBtnDisabled} className="flatBtn-clicked" style={{ borderColor: mainColor(), color: mainColor() }} onClick={() => setDeleteContainerVisiability(false)}>إلغاء</button>
            </div>
        </div>
    )
}