import { useEffect, useState, useContext } from 'react';
import './SellsRecords.css';
import Axios from 'axios';
import { useHistory } from 'react-router';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function SellsRecords(props) {
    const [allSells, setAllSells] = useState([]);
    const [sells, setSells] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const date = new Date();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [totalNumberOfBooks, setTotalNumberOfBooks] = useState(0);
    const [maxExpectedProfit, setMaxExpectedProfit] = useState(0);
    const [minExpectedProfit, setMinExpectedProfit] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const history = useHistory();
    const apiEndPoint = props.apiEndPoint;
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);

    const deleteBtn = (id) => {
        Axios.get(`${apiEndPoint}/api/deleteSellsRecord/${id}`).then(response => {
            if (response.data) {
                setSells(sells.filter(sell => sell.id !== id));
            }
        });
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    const moreInfoOnSell = (infoObj) => {
        history.push('/admin/sellsRecords/Record', {recordInfo: infoObj});
    }

    useEffect(() => {
        Axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
        document.title = 'Paperbook - Sells Records';
        Axios.get(`${apiEndPoint}/api/getSellsRecords`).then(response => {
            setSells(response.data);
            setAllSells(response.data);
            setIsLoading(false);
        });

        Axios.get(`${apiEndPoint}/api/admin/totalNumberOfBooks`).then(result => {
            setTotalNumberOfBooks(result.data[0]['SUM(quantity)']);
        });

        Axios.get(`${apiEndPoint}/api/admin/getExpectedProfitsInfo`).then(result => {
            let maxProfit = 0;
            let minProfit = 0;
            let revenue = 0;
            let books = result.data;
            for (let i=0;i<books.length;i++) {
                maxProfit += books[i].singleSellProfit * books[i].quantity;
                minProfit += (books[i].singleSellProfit - 5) * books[i].quantity;
                revenue += books[i].price * books[i].quantity;
            }
            setMinExpectedProfit(minProfit);
            setMaxExpectedProfit(maxProfit);
            setTotalRevenue(revenue);
        });
    }, []);

    const totalBooksSold = (arr) => {
        let total = 0;
        for (let i=0;i<arr.length;i++) {
            let cart = JSON.parse(arr[i].userCart);
            let keys = Object.keys(cart);
            for (let key of keys) {
                total += cart[key].quantity;
            }
        }
        return total;
    }

    const totalNumberOfDeliveries = (arr) => {
        let total = arr.length;
        return total;
    }

    const totalProfits = (arr) => {
        let total = 0;
        for (let i=0;i<arr.length;i++) {
            total += arr[i].totalProfit;
        }
        return total;
    }

    const totalIncome = (arr) => {
        let total = 0;
        for (let i=0;i<arr.length;i++) {
            total += arr[i].totalPrice;
        }
        return total;
    }

    const filter = () => {
        if (startDate && endDate) {
            setIsBtnDisabled(true);
            Axios.get(`${apiEndPoint}/api/getSellsInRange/${startDate}/${endDate}`).then(response => {
                setSells(response.data);
                document.getElementById('resetBtn').style.display = 'inline-block';
                setIsBtnDisabled(false);
            });
        } else {
            showWarning('الرجاء التأكد من القيم المدخلة كتواريخ');
            window.scrollTo(0, 0);
        }
    }

    console.log(sells);

    if (isLoading) {
        return (
            <h1>Loading</h1>
        )
    }

    console.log(startDate);
    console.log(endDate);

    const showWarning = (str) => {
        const warning = document.querySelector('#sellsRecordsWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }

    const reset = () => {
        setSells(allSells);
        document.getElementById('resetBtn').style.display = 'none';
    }

    return (
        // <div className="sellsRecords">
        //     <table>
        //         <tr>
        //             <th>إجراءات</th>
        //             <th>التاريخ</th>
        //             <th>الكتب</th>
        //             <th>السعر</th>
        //             <th>رقم الهاتف</th>
        //             <th>الإسم</th>
        //             <th> </th>
        //         </tr>
        //         {
        //             sells.map(val => {
        //                 let date = val.time.split('T');
        //                 date[1] = date[1].slice(0, 5);
        //                 return (
        //                     <tr>
        //                         <div className="driverActionBtns">
        //                             <button className="flatBtn" onClick={() => deleteBtn(val.id)}><i class="fas fa-check"></i> حذف</button>
        //                         </div>
        //                         <td>{date[0]}  {date[1]}</td>
        //                         {showItems(JSON.parse(val.userCart))}
        //                         <td>{val.price}</td>
        //                         <td>{val.userPhoneNumber}</td>
        //                         <td>{val.username}</td>
        //                         <td>{val.id}</td>
        //                     </tr>
        //             )})
        //         }
        //     </table>
        //     <div className="smallSize">
        //         <h1>سجل المبيعات</h1>
        //         {
        //             sells.map(val => {
        //                 let date = val.time.split('T');
        //                 date[1] = date[1].slice(0, 5);
        //                 return (
        //                     <div className="mdsm">
        //                         <p><b> الإسم: </b>{val.username}</p>
        //                         <p><b> رقم الهاتف: </b>{val.userPhoneNumber}</p>
        //                         <p><b>السعر: </b>{val.price}د.ل</p>
        //                         <p><b>التاريخ: </b>{date[1]}  {date[0]} </p>
        //                         <p><b>:الكتب </b>{showItems(JSON.parse(val.userCart))}</p>
        //                         <button className="flatBtn" onClick={() => deleteBtn(val.id)}><i class="fas fa-check"></i> حذف</button>
        //                         <p></p>
        //                     </div>
        //                 )
        //             })
        //         }
        //     </div>
        // </div>
        <>
            <div className="warning" id="sellsRecordsWarning"></div>
            <div className="sellsRecord">
                <h1>سجل المبيعات</h1>
                <input style={{marginRight: '.5em'}} type="date" name="startDate" value={startDate} onChange={e => setStartDate(e.target.value)}/>
                <label>من</label>
                <br />
                <input style={{marginRight: '.5em'}} type="date" name="endDate" value={endDate} onChange={e => setEndDate(e.target.value)}/>
                <label>الى</label>
                <br />
                <button style={{marginTop: '1em', backgroundColor: mainColor(), boxShadow: shadowColor()}} disabled={isBtnDisabled} className="btn" onClick={filter}>{isBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'فلترة'}</button>
                <br />
                <button id="resetBtn" style={{marginTop: '1em', display: 'none', color: mainColor(), boxShadow: shadowColor()}} className="btn-clicked" onClick={reset}>الغاء</button>
                <div className="totalSellsInfoContainer">
                    <h2>عدد الطلبيات: {totalNumberOfDeliveries(sells)}</h2>
                    <h2>عدد الكتب المباعة: {totalBooksSold(sells)}</h2>
                    <h2>المدخول الكلي: {totalIncome(sells)} د.ل</h2>
                    <h2>صافي الارباح: {totalProfits(sells)} د.ل</h2>
                </div>
                <div className="totalSellsInfoContainer">
                    <h2>عدد الكتب في المخزن: {totalNumberOfBooks}</h2>
                    <h2>المدخول الكلي المتوقع: {totalRevenue} د.ل</h2>
                    <h2>الارباح المتوقعة: {minExpectedProfit} ~ {maxExpectedProfit} د.ل</h2>
                </div>
                <table>
                    <tr>
                        <th style={{backgroundColor: mainColor(), boxShadow: shadowColor()}}>بيعت بطريقة</th>
                        <th style={{backgroundColor: mainColor(), boxShadow: shadowColor()}}>التاريخ</th>
                        <th style={{backgroundColor: mainColor(), boxShadow: shadowColor()}}>الربح</th>
                        <th style={{backgroundColor: mainColor(), boxShadow: shadowColor()}}>السعر</th>
                        <th style={{backgroundColor: mainColor(), boxShadow: shadowColor()}}>السائق</th>
                        <th style={{backgroundColor: mainColor(), boxShadow: shadowColor()}}>إسم المشتري</th>
                        <th style={{backgroundColor: mainColor(), boxShadow: shadowColor()}}>#</th>
                    </tr>
                    {
                        sells.map(val => {
                            return (
                                <tr style={{cursor: 'pointer'}} onClick={() => moreInfoOnSell(val)}>
                                    <td>{(val.manual) ? 'يدوية' : 'تلقائية'}</td>
                                    <td>{val.deliveryTime}</td>
                                    <td>{val.totalProfit}</td>
                                    <td>{val.totalPrice}</td>
                                    <td>{(val.driverName) ? val.driverName : 'شاغر'}</td>
                                    <td>{val.username}</td>
                                    <td>{val.id}</td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
        </>
    )
}