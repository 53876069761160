import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './PastOrders.css';
import axios from 'axios';

export default function PastOrders(props) {
    const [orders, setOrders] = useState([]);
    const [finishedOrders, setFinishedOrders] = useState([]);
    const [canceledOrders, setCanceledOrders] = useState([]);
    const [imgsObject, setImgsObject] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const apiEndPoint = props.apiEndPoint;
    useEffect(() => {
        let tempOrders = [];
        axios.get(`${apiEndPoint}/api/getUserPastOrders/${props.username}`).then(response1 => {
            for (let i=0;i<response1.data.length;i++) {
                let cart = JSON.parse(response1.data[i].cart);
                // axios.post(`${apiEndPoint}/api/getImagesForPastOrdersBooks`, { arrayOfNames: keys }).then(result => {
                //     for (let j=0;j<keys.length;j++) {
                //         if (result.data[j][0]) {
                //             cart[keys[j]].img = result.data[j][0].img;
                //         } else {
                //             cart[keys[j]].img = result.data[j].img;
                //         }
                //     }
                // });
                setOrders(orders => [...orders, { cart: cart, id: response1.data[i].id, purchaseDate: response1.data[i].purchaseDate }]);
                tempOrders.push(cart);  
            }
            axios.get(`${apiEndPoint}/api/getUserFinishedOrders/${props.username}`).then(response2 => {
                for (let i=0;i<response2.data.length;i++) {
                    let cart = JSON.parse(response2.data[i].userCart);
                    // axios.post(`${apiEndPoint}/api/getImagesForPastOrdersBooks`, { arrayOfNames: keys }).then(result => {
                    //     for (let j=0;j<keys.length;j++) {
                    //         if (result.data[j][0]) {
                    //             cart[keys[j]].img = result.data[j][0].img;
                    //         } else {
                    //             cart[keys[j]].img = result.data[j].img;
                    //         }
                    //     }
                    // });
                    setFinishedOrders(finishedOrders => [...finishedOrders, {cart: cart, id: response2.data[i].id , deliveryTime: response2.data[i].deliveryTime, purchaseDate: response2.data[i].purchaseDate }]);
                    tempOrders.push(cart);
                }
                axios.get(`${apiEndPoint}/api/getUserCanceledOrders/${props.username}`).then(response => {
                    for (let i=0;i<response.data.length;i++) {
                        let cart = JSON.parse(response.data[i].cart);
                        // axios.post(`${apiEndPoint}/api/getImagesForPastOrdersBooks`, { arrayOfNames: keys }).then(result => {
                        //     for (let j=0;j<keys.length;j++) {
                        //         if (result.data[j][0]) {
                        //             cart[keys[j]].img = result.data[j][0].img;
                        //         } else {
                        //             cart[keys[j]].img = result.data[j].img;
                        //         }
                        //     }
                        // });
                        setCanceledOrders(canceledOrders => [...canceledOrders, { cart: cart, id: response.data[i].id, purchaseDate: response.data[i].purchaseDate }]);
                        tempOrders.push(cart);
                    }
                    console.log(tempOrders);
                    let arrayOfNames = [];
                    for (let i=0;i<tempOrders.length;i++) {
                        for (let key of Object.keys(tempOrders[i])) {
                            arrayOfNames.push(key);
                        }
                    }
                    arrayOfNames = Array.from(new Set(arrayOfNames));
                    console.log(arrayOfNames);
                    axios.post(`${apiEndPoint}/api/getImagesForPastOrdersBooks`, { arrayOfNames }).then(result => {
                        console.log(result);
                        let tempObj = {};
                        for (let i=0;i<arrayOfNames.length;i++) {
                            console.log(result.data[i]);
                            if (result.data[i]) {
                                console.log(result.data[i]);
                                tempObj[arrayOfNames[i]] = result.data[i][0].img;
                            }
                        }
                        console.log(tempObj);
                        setImgsObject(tempObj);
                    });
                    setIsLoading(false);
                });
            });
        });
    }, []);

    const pastOrder = (order, isDone, isCanceled) => {
        history.push('/cart/pastOrder', {order: order, isDone: isDone, isCanceled: isCanceled, imgsObject: imgsObject});
    }


    console.log(finishedOrders);
    console.log(orders);
    console.log(canceledOrders);
    console.log(imgsObject);


    if (isLoading) {
        return (
            <div className="pastOrders">
                <div className="bookData pastOrdersBookData">
                    <div className="cardContainer">
                        <img style={{height: '130px', width: '95px'}} className="skeleton" alt="" />
                    </div>
                    <div className="cardInfo">
                        <h3 style={{textAlign: 'right', color: 'transparent', marginBottom: '.4em'}} className="skeleton"> lskkjflksjdf </h3>
                        <h3 className="subTitle skeleton" style={{textAlign: 'right', color: 'transparent'}}> sdjflksjflks </h3>
                    </div>
                </div>
                <div className="bookData pastOrdersBookData">
                    <div className="cardContainer">
                        <img style={{height: '130px', width: '95px'}} className="skeleton" alt="" />
                    </div>
                    <div className="cardInfo">
                        <h3 style={{textAlign: 'right', color: 'transparent', marginBottom: '.4em'}} className="skeleton"> lskkjflksjdf </h3>
                        <h3 className="subTitle skeleton" style={{textAlign: 'right', color: 'transparent'}}> sdjflksjflks </h3>
                    </div>
                </div>
            </div>
        )
    }

    if (canceledOrders.length + orders.length + finishedOrders.length === 0) {
        return <div></div>
    } else {
        return (
            <>
                <h2>:الطلبيات المعلقة</h2>
                <div className="pastOrders">
                    {   
                        (orders.length > 0) ? orders.map(order => {
                            return (
                                <div className="pastOrder" onClick={() => pastOrder(order, false, false)}>
                                    <div className='pastOrderImgs'>
                                        {
                                            Object.keys(order.cart).map((title, index) => {
                                                if (index < 2) {
                                                    return (
                                                        <img style={{marginLeft: 0 - index * 30, height: 70 + (index * 5), width: 50 + (index * 5)}} src={imgsObject[title]} alt="" />
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <b>{order.id}# طلبية</b>
                                </div>
                            )
                        }) : null
                    }
                    {   
                        (finishedOrders.length > 0) ? finishedOrders.map(order => {
                            return (
                                <div className="pastOrder" onClick={() => pastOrder(order, true, false)}>
                                    <div className='pastOrderImgs'>
                                        {
                                            Object.keys(order.cart).map((title, index) => {
                                                if (index < 2) {
                                                    return (
                                                        <img style={{marginLeft: 0 - index * 30, height: 70 + (index * 5), width: 50 + (index * 5)}} src={imgsObject[title]} alt="" />
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <b style={{color: 'green'}}>{order.id}# طلبية</b>
                                </div>
                            )
                        }) : null
                    }
                    {   
                        (canceledOrders.length > 0) ? canceledOrders.map(order => {
                            return (
                                <div className="pastOrder" onClick={() => pastOrder(order, false, true)}>
                                    <div className='pastOrderImgs'>
                                        {
                                            Object.keys(order.cart).map((title, index) => {
                                                if (index < 2) {
                                                    console.log(imgsObject[title]);
                                                    return (
                                                        <img style={{marginLeft: 0 - index * 30, height: 70 + (index * 5), width: 50 + (index * 5)}} src={imgsObject[title]} alt="" />
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <b style={{color: 'var(--main-color)'}}>{order.id}# طلبية</b>
                                </div>
                            )
                        }) : null
                    }
                </div>
            </>
        )
    }

}