import './PastOrder.css';
import { useLocation, Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { ActiveTheme } from '../../App';
import { useContext, useState, useEffect, useRef } from 'react';
import barcodeIcon from '../../assets/barcode.jpg';



export default function PastOrder(props) {
    const location = useLocation();
    const history = useHistory();
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const [user, setUser] = useState();
    const {order, isDone, isCanceled, imgsObject} = location.state ?? props;
    const isCart = props.isCart ?? false;
    const receipt = useRef();
    const apiEndPoint = props.apiEndPoint;
    console.log(order);
    console.log(imgsObject);

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const totalPrice = () => {
        let price = 0;
        if (order.cart) {
            let cart = order.cart;
            for (let key of Object.keys(cart)) {
                price += cart[key].quantity * (cart[key].singleBookPrice - cart[key].singleSellSale);
            }
        }
        return price;
    }

    const deleteEntry = () => {
        const submitBtn = document.getElementById('pastOrderSubmitBtn');
        submitBtn.disabled = true;
        submitBtn.classList.remove('flatBtn');
        submitBtn.classList.add('flatBtn-clicked');
        axios.get(`${apiEndPoint}/api/deleteFromCanceled/${order.id}`).then(response => {
            if (response.data) {
                history.goBack();
            }
        });
    }

    const numberOfBooks = () => {
        let sum = 0;
        if (order.cart) {
            for (let key of Object.keys(order.cart)) {
                sum += order.cart[key].quantity;
            }
        }
        return sum;
    }

    const goHome = () => {
        document.body.style.overflowY = 'scroll';
        document.querySelector('nav').style.display = 'block';
        history.push("/");
    }

    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then((response) => {
            if (response.data.loggedin === false) {
                window.location.replace('/login');
            }
            setUser(response.data.user);
        });
    }, []);

    console.log(order);
    console.log(user);

    return (
        <div className="PastOrderContainer">
            {/* <h1 style={{color: (isDone) ? 'green' : (isCanceled) ? mainColor() : 'black'}}>{(isDone) ? (<i class="fas fa-clipboard-check"></i>) : (isCanceled) ? (<i class="fas fa-times-circle"></i>) : (<i class="fas fa-truck"></i>)} <br /> طلبية #{order.id}</h1>
            {(isDone) ? (<p style={{color: 'green', margin: 0}}> تم التوصيل</p>) : (isCanceled) ? (<p style={{color: mainColor(), margin: 0}}> تم الغاء الطلبية</p>) : null}
            {
                Object.keys(order.cart).map(title => (
                    <div className="cartCard">
                        <div className="cartCardRightSide">
                            <h3>{title}</h3>
                            <p>{order.cart[title].singleBookPrice - order.cart[title].singleSellSale}د.ل</p>
                            <div className="counter">{(order.cart[title].quantity == 1) ? 'كتاب' : (order.cart[title].quantity == 2) ? 'كتابان' : `${order.cart[title].quantity} كتب`}</div>
                        </div>
                        <img style={(order.cart[title].genre === 'كروت') ? {width: '100px', height: '65px'} : null} src={order.cart[title].img} alt="" />
                    </div>
                ))
            }
            <b>المجموع: {totalPrice()}د.ل</b>
            <p className="bottomText" style={{marginTop: 0, textAlign: 'center'}}>الاسعار لا تشمل التوصيل لمعرفة سعر التوصيل <Link to="/deliveryPrices">اضغط هنا</Link></p>
            {(isCanceled) ? (<button id="pastOrderSubmitBtn" className="flatBtn" style={{backgroundColor: mainColor()}} onClick={deleteEntry}>حذف</button>) : null} */}
            <div className="PastOrder" ref={receipt}>
                <div className="PastOrderContainer-Header" style={{backgroundColor: mainColor(), color: activeTheme === 'black' ? '#ffffff' : null}}>
                    <div className='pastOrderImgs'>
                        {
                            order.cart && Object.keys(order.cart).map((title, index) => {
                                if (index < 2) {
                                    return (
                                        <img style={{marginLeft: 0 - index * 30, height: 70 + (index * 5), width: 50 + (index * 5)}} src={imgsObject[title]} alt="" />
                                    )
                                }
                            })
                        }
                    </div>
                    <div>
                        <p style={{display: order.id ? null : 'none'}}># {order.id ?? ''}</p>
                        <h3>{numberOfBooks() === 1 ? 'كتاب واحد' : numberOfBooks() === 2 ? 'كتابان' : numberOfBooks() > 3 && numberOfBooks() < 11 ? `${numberOfBooks()} كتب` : `${numberOfBooks()} كتاباً` }</h3>
                    </div>
                </div>
                <div className="PastOrderContainer-Body">
                    <div className="PastOrderContainer-BodyInfoSection">
                        <div>
                            <h4>المشتري:</h4>
                            <p>{user?.name}</p>
                        </div>
                        <div>
                            <h4>المجموع:</h4>
                            <p>{totalPrice()}</p>
                        </div>
                        <div>
                            <h4>تاريخ الاستلام:</h4>
                            <p>{isDone ? order.deliveryTime : isCanceled ? 'تم الغاء الطلبية' : 'جاري تحضير الطلب'}</p>
                        </div>
                        <div>
                            <h4>تاريخ الشراء:</h4>
                            <p>{order.purchaseDate}</p>
                        </div>
                    </div>
                    <div className="PastOrderContainer-BodyCartSection">
                        <h3>المشتريات:</h3>
                        {
                            order.cart && Object.keys(order.cart).map(title => (
                                <Link to={isCart ? null : `/book/${title}`}>
                                    <div className="PastOrderContainer-BodyCartItem">
                                        <img style={(order.cart[title].genre === 'كروت') ? {width: '100px', height: '65px'} : null} src={imgsObject[title]} alt="" />
                                        <div className="PastOrderContainer-BodyCartItemRightSide">
                                            <h4>{title}</h4>
                                            <p>{(order.cart[title].quantity == 1) ? 'كتاب واحد' : (order.cart[title].quantity == 2) ? 'كتابان' : `${order.cart[title].quantity} كتب`}</p>
                                            <p>{(order.cart[title].singleBookPrice - order.cart[title].singleSellSale) * order.cart[title].quantity} د.ل</p>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
                    <div className="PastOrderContainer-BodyButtonSection">
                        <img src={barcodeIcon} alt="" />
                        <p className="bottomText" style={{marginTop: 0, textAlign: 'center'}}>الاسعار لا تشمل التوصيل لمعرفة سعر التوصيل <Link to="/deliveryPrices">اضغط هنا</Link></p>
                        {(isCanceled) ? (<button id="pastOrderSubmitBtn" className="flatBtn" style={{backgroundColor: mainColor()}} onClick={deleteEntry}>حذف</button>) : null} 
                        {(isCart) ? (<Link to="/" style={{color: mainColor(), fontWeight: 'bold'}} onClick={goHome}><i class="fas fa-home"></i> العودة للصفحة الرئيسية</Link>) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}