import IconedInputField from '../IconedInputField/IconedInputField';
import './Contact.css';
import person from '../../assets/person.svg';
import redPhone from '../../assets/redPhone.svg';
import messageIcon from '../../assets/message.svg';
import phone from '../../assets/phone.svg';
import location from '../../assets/location.svg';
import email2 from '../../assets/email2.svg';
import { ActiveTheme } from '../../App';
import { useContext, useState } from 'react';
import Axios from 'axios';

export default function Contact(props) {
    const [username, setUsername] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [message, setMessage] = useState();
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    document.title = 'Paperbook - Contact Us';
    
    const apiEndPoint = props.apiEndPoint;
    console.log(apiEndPoint);

    const submitForm = () => {
        if (message !== '' && phoneNumber !== '' && username !== '') {
            const contactFormSubmitBtn = document.getElementById('contactFormSubmitBtn');
            contactFormSubmitBtn.disabled = true;
            contactFormSubmitBtn.classList.remove('btn');
            contactFormSubmitBtn.classList.add('btn-clicked');
            Axios.get(`${apiEndPoint}/api/addNotification/${message}/${username}/${phoneNumber}/ContactForm`).then(response => {
                if (response.data) {
                    contactFormSubmitBtn.innerHTML = '<i class="fas fa-check"></i>تم الارسال'
                }
            });
        }
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, 0.4) 0px 5px, rgba(17,17,17, 0.3) 0px 10px, rgba(17,17,17, 0.2) 0px 15px, rgba(17,17,17, 0.1) 0px 20px, rgba(17,17,17, 0.05) 0px 25px'
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, 0.4) 0px 5px, rgba(151,201,233, 0.3) 0px 10px, rgba(151,201,233, 0.2) 0px 15px, rgba(151,201,233, 0.1) 0px 20px, rgba(151,201,233, 0.05) 0px 25px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, 0.4) 0px 5px, rgba(93,187,79, 0.3) 0px 10px, rgba(93,187,79, 0.2) 0px 15px, rgba(93,187,79, 0.1) 0px 20px, rgba(93,187,79, 0.05) 0px 25px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, 0.4) 0px 5px, rgba(130,124,180, 0.3) 0px 10px, rgba(130,124,180, 0.2) 0px 15px, rgba(130,124,180, 0.1) 0px 20px, rgba(130,124,180, 0.05) 0px 25px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, 0.4) 0px 5px, rgba(240,91,93, 0.3) 0px 10px, rgba(240,91,93, 0.2) 0px 15px, rgba(240,91,93, 0.1) 0px 20px, rgba(240,91,93, 0.05) 0px 25px';
        } else { // yellow
            return 'rgba(253,183,27, 0.4) 0px 5px, rgba(253,183,27, 0.3) 0px 10px, rgba(253,183,27, 0.2) 0px 15px, rgba(253,183,27, 0.1) 0px 20px, rgba(253,183,27, 0.05) 0px 25px';
        }
    }

    return (
        <div className="contact">
            <h1 className="contactHeader">لديك سؤال؟ تواصل معنا</h1>
            <div className="formContainer" style={{boxShadow: shadowColor()}}>
                <div className="formSection">
                    <div className="question">
                        <label>:الإسم</label>
                        <IconedInputField image={person} onChange={e => setUsername(e.target.value)}/>
                    </div>
                    <div className="question">
                        <label>:رقم الهاتف</label>
                        <IconedInputField image={redPhone} onChange={e => setPhoneNumber(e.target.value)}/>
                    </div>
                    <div className="question">
                        <label>:الرسالة</label>
                        <div className="iconedInputField shadow">
                            <textarea className="contactMessageBox" onChange={e => setMessage(e.target.value)}/><img src={messageIcon} alt=""/>
                        </div>
                    </div>
                    <button style={{backgroundColor: mainColor()}} id="contactFormSubmitBtn" className="btn" onClick={submitForm}>إرسال</button>
                </div>
                <div className="infoSection" style={{backgroundColor: mainColor()}}>
                    <h1>بيانات التواصل</h1>
                    <p>قم بتعبأة النموذج, أو قم بالتواصل معنا عبر المعلومات التالية</p>
                    <div className="info">
                        <img src={phone} alt="" width="30"/><p>0919877888</p>
                    </div>
                    <div className="info">
                        <img src={location} alt="" width="25"/><p>Tripoli, Libya</p>
                    </div>
                    <div className="info">
                        <img src={email2} alt="" width="30"/><p>Info@paperbook.ly</p>
                    </div>
                    <div className="socialLinks">
                        <a href="https://www.facebook.com/paperbook.ly/" style={{color: 'white'}} target="_blank"><i class="fab fa-facebook"></i></a>
                        <a href="https://instagram.com/paperbook.ly?utm_medium=copy_link" style={{color: 'white'}} target="_blank"><i class="fab fa-instagram"></i></a>
                        <a href="https://wa.me/message/CYEWTWURGK3IA1" style={{color: 'white'}} target="_blank"><i class="fab fa-whatsapp"></i></a>
                        <a href="https://t.me/paperbookly" style={{color: 'white'}} target="_blank"><i class="fab fa-telegram-plane"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}