import './AdminBookAdder.css';
import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import IconedInputField from '../IconedInputField/IconedInputField';
import titleIcon from '../../assets/title.svg';
import authorIcon from '../../assets/author.svg';
import pagesIcon from '../../assets/pages.svg';
import genreIcon from '../../assets/genre.svg';
import priceIcon from '../../assets/price.svg';
import quantityIcon from '../../assets/quantity.svg';
import descriptionIcon from '../../assets/message.svg';
import { useHistory } from 'react-router';
import profitIcon from '../../assets/profit.svg';
import pdfIcon from '../../assets/pdfIcon.svg';
import { ActiveTheme } from '../../App';
import { Ring } from '@uiball/loaders';

export default function AdminBookAdder(props) {
    const [bookTitle, setBookTitle] = useState('');
    const [bookAuthor, setBookAuthor] = useState('');
    const [bookPages, setBookPages] = useState('');
    const [bookGenre, setBookGenre] = useState('');
    const [bookPrice, setBookPrice] = useState('');
    const [bookQuantity, setBookQuantity] = useState('');
    const [bookDescription, setBookDescription] = useState('');
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);
    const { activeTheme, setActiveTheme } = useContext(ActiveTheme);
    const [img, setImg] = useState();
    const history = useHistory();

    const apiEndPoint = props.apiEndPoint;

    const showWarning = (str) => {
        const warning = document.querySelector('#bookAdderWarning');
        const warningDiv = document.createElement('div');
        warningDiv.innerText = str;
        warning.appendChild(warningDiv);
        warning.style.display = 'block';
        warning.style['max-width'] = '415px';
        warning.style['background-color'] = mainColor();
        warning.classList.add('fadein');
        window.scrollTo(0, 0);
        setTimeout(() => {
            warning.classList.remove('fadein');
            warning.classList.add('fadeout');
        }, 10000);
        setTimeout(() => {
            warning.style.display = 'none';
            warningDiv.remove();
        }, 10300);
        warning.classList.remove('fadeout');
    }
    
    const checking = async(formData) => {
        let result = true;
        if (formData.get('title').length == 0) {
            showWarning('الرجاء ادخال عنوان للكتاب');
            result = false;
        }
        if (formData.get('author').length == 0) {
            showWarning('الرجاء ادخال اسم الكاتب');
            result = false;
        }
        if (isNaN(formData.get('pages')) || formData.get('pages').length == 0) {
            showWarning('الرجاء ادخال قيمة صالحة لعدد الصفحات');
            result = false;
        }
        if (isNaN(formData.get('quantity')) || formData.get('quantity').length == 0) {
            showWarning('الرجاء ادخال قيمة صالحة للكمية');
            result = false;
        }
        if (isNaN(formData.get('price')) || formData.get('price').length == 0) {
            showWarning('الرجاء ادخال قيمة صالحة للسعر');
            result = false;
        }
        if (formData.get('description').length == 0) {
            showWarning('الرجاء ادخال وصف للكتاب');
            result = false;
        }
        if (isNaN(formData.get('singleSellProfit')) || formData.get('singleSellProfit').length == 0) {
            showWarning('الرجاء ادخال قيمة للربح');
            result = false;
        }
        if (isNaN(formData.get('sale'))) {
            showWarning('الرجاء ادخال قيمة صالحة للتخفيض للكل');
            result = false;
        }
        if (formData.get('bookCover').name.length == 0) {
            showWarning('الرجاء اختيار غلاف للكتاب');
            result = false;
        }
        const sale = formData.get('sale');
        const singleSellProfit = formData.get('singleSellProfit');
        const topFanSale = formData.get('topFanSale');
        console.log(topFanSale);
        if (Number(topFanSale)) {
            if (singleSellProfit - (sale + 5) < 0) {
                console.log(1);
                showWarning("قيمة الربح لا يمكن ان تقل عن 0.");
                result = false;
            }
        } else if (singleSellProfit - sale < 0) {
            console.log(2);
            showWarning("قيمة الربح لا يمكن ان تقل عن 0.");
            result = false;
        }
        return result;
    }

    const mainColor = () => {
        if (activeTheme === 'black') {
            return 'rgb(17,17,17)';
        } else if (activeTheme === 'blue') {
            return 'rgb(151,201,233)';
        } else if (activeTheme === 'green') {
            return 'rgb(93,187,79)';
        } else if (activeTheme === 'purple') {
            return 'rgb(130,124,180)';
        } else if (activeTheme === 'red') {
            return 'rgb(240,91,93)';
        } else { // yellow
            return 'rgb(253,183,27)';
        }
    }

    const shadowColor = () => {
        if (activeTheme === 'black') {
            return 'rgba(17,17,17, .2) 0 -25px 18px -14px inset,rgba(17,17,17, .15) 0 1px 2px,rgba(17,17,17, .15) 0 2px 4px,rgba(17,17,17, .15) 0 4px 8px,rgba(17,17,17, .15) 0 8px 16px,rgba(17,17,17, .15) 0 16px 32px';
        } else if (activeTheme === 'blue') {
            return 'rgba(151,201,233, .2) 0 -25px 18px -14px inset,rgba(151,201,233, .15) 0 1px 2px,rgba(151,201,233, .15) 0 2px 4px,rgba(151,201,233, .15) 0 4px 8px,rgba(151,201,233, .15) 0 8px 16px,rgba(151,201,233, .15) 0 16px 32px';
        } else if (activeTheme === 'green') {
            return 'rgba(93,187,79, .2) 0 -25px 18px -14px inset,rgba(93,187,79, .15) 0 1px 2px,rgba(93,187,79, .15) 0 2px 4px,rgba(93,187,79, .15) 0 4px 8px,rgba(93,187,79, .15) 0 8px 16px,rgba(93,187,79, .15) 0 16px 32px';
        } else if (activeTheme === 'purple') {
            return 'rgba(130,124,180, .2) 0 -25px 18px -14px inset,rgba(130,124,180, .15) 0 1px 2px,rgba(130,124,180, .15) 0 2px 4px,rgba(130,124,180, .15) 0 4px 8px,rgba(130,124,180, .15) 0 8px 16px,rgba(130,124,180, .15) 0 16px 32px';
        } else if (activeTheme === 'red') {
            return 'rgba(240,91,93, .2) 0 -25px 18px -14px inset,rgba(240,91,93, .15) 0 1px 2px,rgba(240,91,93, .15) 0 2px 4px,rgba(240,91,93, .15) 0 4px 8px,rgba(240,91,93, .15) 0 8px 16px,rgba(240,91,93, .15) 0 16px 32px';
        } else { // yellow
            return 'rgba(253,183,27, .2) 0 -25px 18px -14px inset,rgba(253,183,27, .15) 0 1px 2px,rgba(253,183,27, .15) 0 2px 4px,rgba(253,183,27, .15) 0 4px 8px,rgba(253,183,27, .15) 0 8px 16px,rgba(253,183,27, .15) 0 16px 32px';
        }
    }

    const submitBtn = (e) => {
        e.preventDefault();
        const form = document.querySelector("#bookInfoForm");
        const formData = new FormData(form);
        checking(formData).then(result => {
            if (result) {
                setIsBtnDisabled(true);
                axios.post(`${apiEndPoint}/api/admin/insertBook`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(response => {
                    if (!response.data) {
                        setIsBtnDisabled(false);
                        showWarning('كتاب بهذا العنوان موجود بالفعل');
                        window.scrollTo(0, 0);
                    } else if (response.data) {
                        history.replace('/admin');
                    }
                });
            }
        });
    }

    useEffect(() => {
        axios.get(`${apiEndPoint}/api/login`).then(response => {
            if (response.data.loggedin === false || response.data.user.isAdmin === 0) {
                history.replace("/");
            }
        });
        document.title = 'Paperbook - Admin Panel';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="warning" id="bookAdderWarning"></div>
            <div className="adminBookEditor">
                {
                (img) ? (
                    <img src={URL.createObjectURL(img)} alt="" className="bookCover"/>
                ) : (<label for="bookCover" className="bookCover bookCoverDiv"><i class="fas fa-plus-square"></i></label>)
                }
                <div className="adminEditorForm">
                    <form action={`${apiEndPoint}/api/admin/insertBook`} method="post" encType="multipart/form-data" id="bookInfoForm">
                        <IconedInputField type="text" name="title" image={titleIcon} placeholder="العنوان" value={bookTitle} onChange={(e) => setBookTitle(e.target.value)}/>
                        <IconedInputField type="text" name="author" image={authorIcon} placeholder="الكاتب" value={bookAuthor} onChange={(e) => setBookAuthor(e.target.value)}/>
                        <IconedInputField type="text" name="pages" image={pagesIcon} placeholder="الصفحات" value={bookPages} onChange={(e) => setBookPages(e.target.value)}/>
                        <IconedInputField type="text" name="quantity" image={quantityIcon} placeholder="الكمية" value={bookQuantity} onChange={(e) => setBookQuantity(e.target.value)}/>
                        <IconedInputField type="text" name="price" image={priceIcon} placeholder="السعر" value={bookPrice} onChange={(e) => setBookPrice(e.target.value)}/>
                        <div className="iconedInputField shadow">
                            <select name="genre" className="selectionBox">
                                <option value="ديني">ديني</option>
                                <option value="رواية">رواية</option>
                                <option value="نصوص">نصوص</option>
                                <option value="تطوير ذات">تطوير ذات</option>
                                <option value="علوم">علوم</option>
                                <option value="مذكرات">مذكرات</option>
                                <option value="علم النفس">علم النفس</option>
                                <option value="مانجا">مانجا</option>
                                <option value="تفاعلي">تفاعلي</option>
                                <option value="ورقة">ورقة</option>
                                <option value="حصري">حصري</option>
                                <option value="كتب اصلية">كتب اصلية</option>
                                <option value="طلب مسبق">طلب مسبق</option>
                                <option value="اكسسوارات">اكسسوارات</option>
                                <option value="كتب الكترونية">كتب الكترونية</option>
                                <option value="كتب انجليزية"></option>
                                <option value="كروت">كروت</option>
                            </select><img src={genreIcon} alt="" />
                        </div>
                        <div className="iconedInputField shadow">
                            <select name="secondGenre" className="selectionBox">
                                <option value="">لا يوجد</option>
                                <option value="ديني">ديني</option>
                                <option value="رواية">رواية</option>
                                <option value="نصوص">نصوص</option>
                                <option value="تطوير ذات">تطوير ذات</option>
                                <option value="علوم">علوم</option>
                                <option value="مذكرات">مذكرات</option>
                                <option value="علم النفس">علم النفس</option>
                                <option value="مانجا">مانجا</option>
                                <option value="تفاعلي">تفاعلي</option>
                                <option value="ورقة">ورقة</option>
                                <option value="حصري">حصري</option>
                                <option value="كتب اصلية">كتب اصلية</option>
                                <option value="طلب مسبق">طلب مسبق</option>
                                <option value="اكسسوارات">اكسسوارات</option>
                                <option value="كتب الكترونية">كتب الكترونية</option>
                                <option value="كتب انجليزية">كتب انجليزية</option>
                                <option value="كروت">كروت</option>
                            </select><img src={genreIcon} alt="" />
                        </div>
                        <div className="iconedInputField shadow">
                            <select name="topFanSale" className="selectionBox">
                                <option value="0">لا تخفيض للكتاب</option>
                                <option value="1">تخفيض لاصحاب التوب فان</option>
                            </select><img src={priceIcon} alt="" />
                        </div>
                        <IconedInputField type="text" name="sale" image={priceIcon} placeholder="تخفيض للجميع" />
                        <div className="iconedInputField shadow">
                            <img src={descriptionIcon} alt=""/><textarea maxLength={10000} className="contactMessageBox" placeholder="الوصف" value={bookDescription} onChange={(e) => setBookDescription(e.target.value)} name="description"/>
                        </div>
                        <input type="file" name="bookCover" onChange={(e) => setImg(e.target.files[0])} id="bookCover"/>
                        <IconedInputField type="text" name="singleSellProfit" image={profitIcon} placeholder="الربح" />
                        <IconedInputField type="file" name="pdfFile" image={pdfIcon} placeholder="نسخة الـ PDF" />
                        <button style={{backgroundColor: mainColor()}} disabled={isBtnDisabled} className="flatBtn" onClick={submitBtn}>{isBtnDisabled ? <Ring size={20} lineWeight={5} speed={2} color="white" /> : 'أضف'}</button>
                    </form>
                </div>
            </div>
        </>
    )
}